import React from "react";
import { useIntl } from "gatsby-plugin-intl";
import moment from "moment";

import * as constants from "../../../app.constant";
import customerStore from "../../../store/customerStore";
import deviceStore from "../../../store/deviceStore";
import planStore from "../../../store/planStore";
import AppLayout from "../../../components/shared/AppLayout";
import companyStamp from "../../../assets/images/terms-and-conditions/company-stamp.png";
import { getGenderByNationlId } from "../../../app.utils";

const PageTermsAndConditions07 = () => {
    const intl = useIntl();
    const messages = intl.messages;
    const customerFullName = customerStore.customerFullName;
    const customerDateOfBirth = customerStore.customerDateOfBirth;
    const customerNationalId = customerStore.customerNationalId;
    const devicePurchaseDate = deviceStore.devicePurchaseDate;
    const twoYearsAfterDevicePurchaseDate = moment(devicePurchaseDate).clone().add(2, "years").format(constants.DATE_FORMAT);
    const devicePlanPrice = deviceStore.devicePlanPrice;
    const devicePrice = deviceStore.devicePrice;
    const userSelectedPlan = planStore.planType;
    const customerGender = getGenderByNationlId(customerStore.customerNationalId);

    const samsungCarePremiumPlanContent = {
        body03:
            <p>
                <b>(三)本保險經紀人公司根據要保人/被保人所提供各種資訊及需求之建議內容</b><br/>
                <b>保險公司名稱及概況:</b> 和泰產險<br/>
                <a href={messages["common.website.url"]} target="_blank" rel="noreferrer">{messages["common.website.url"]}</a><br/>
                <b>保險險種名稱:</b> 行動裝置保險<br/>
                <b>保險金額:</b> 新台幣 {devicePrice} 元<br/>
                <b>保險期間:</b> {devicePurchaseDate} ~ {twoYearsAfterDevicePurchaseDate}<br/>
                <b>保障範圍:</b><br/>
                <b>1.</b> 行動裝置意外毀損(泡水損壞)、碾壓、破裂時可以免費維修<br/>
                <b>2.</b> 意外損壞理賠(維修+置換)：一年限3次<br/>
                <br/>
                <b>保險費:</b> 新台幣 {devicePlanPrice} 元<br/>
                <br/>
                <b>建議投保保險公司理由:</b> 客戶指定<br/>
            </p>
    };

    const samsungCareLitePlanContent = {
        body03:
            <p>
                <b>(三)本保險經紀人公司根據要保人/被保人所提供各種資訊及需求之建議內容</b><br/>
                <b>保險公司名稱及概況:</b> 和泰產險<br/>
                <a href={messages["common.website.url"]} target="_blank" rel="noreferrer">{messages["common.website.url"]}</a><br/>
                <b>保險險種名稱:</b> 行動裝置保險<br/>
                <b>保險金額及保障範圍:</b><br/>
                <b>1.</b> 行動裝置意外毀損(泡水損壞)、碾壓、破裂時可以維修，損壞維修或置換每次收取自負額<br/>
                <b>2.</b> 意外損壞理賠(維修+置換)：一年限3次<br/>
                <br/>
                <b>保險費:</b> 新台幣 {devicePlanPrice} 元<br/>
                <br/>
                <b>建議投保保險公司理由:</b> 客戶指定<br/>
            </p>
    };

    const selectedPlanContents = {
        body03: userSelectedPlan === constants.PLAN.PREMIUM ? samsungCarePremiumPlanContent.body03 : samsungCareLitePlanContent.body03
    };

    return (
        <AppLayout hasHeader={true} isBackBtn={true} hasFooter={true}>
            <div className="app-page page-terms-and-conditions">

                <div className="terms-and-conditions">

                    <div className="container">

                        <p className="terms-and-conditions__title">
                            <strong>保特保經書面分析報告</strong>
                        </p>

                        <div className="terms-and-conditions__wrapper">

                            <p>
                                <b>(一) 基本資料</b><br/>
                                <b>要保人:</b> {customerFullName}<br/>
                                <b>被保人:</b> {customerFullName}<br/>
                                <b>性別:</b> {customerGender}<br/>
                                <b>出生年月日:</b> {customerDateOfBirth}<br/>
                                <b>身分證字號:</b> {customerNationalId}<br/>
                            </p>

                            <p>
                                <b>(二) 保險需求</b><br/>
                                <b>本次投保目的及需求:</b> 保障<br/>
                                <b>是否有指定之保險公司？</b> 是，和泰產險<br/>
                                <b>欲投保之保險種類:</b> 行動裝置保險<br/>
                                <b>保險期間:</b> 兩年期<br/>
                                <b>欲投保之保險金額:</b> 新台幣 {devicePrice} 元<br/>
                                <b>是否已有投保其他商業保險之有效保險契約:</b><br/>
                                <b>預估繳交保險費金額:</b> 新台幣 {devicePlanPrice} 元<br/>
                            </p>

                            {selectedPlanContents.body03}

                            <p>
                                <b>備註</b><br/>
                                本分析報告並非保險契約之一部分，本分析報告所建議內容，保險公司是否予以承保及詳細保險商品內容、費率及變更，需以要保文件及投保當時保單條款和保險公司作業規則為準。<br/>
                                <br/>
                                本分析報告係依據要、被保險人所提供各種資訊與需求所製作，並按製作時所可得之資訊而作出，本保險經紀人公司無法保證或承諾本分析報告內容完全正確無誤，抑或保險契約訂立後，將不因法令變更、金融保險市場變化或新金融保險相關資訊之衍生而有所變化或影響。<br/>
                                <br/>
                                <b>保險經紀人姓名/執業證照編號:</b><br/>
                                <img src={companyStamp} alt="" /> <b>/ 105-ELBP000044</b><br/>
                            </p>

                            <p>
                                保險經紀人公司/保險經紀人營業所在地：台北市大安區忠孝東路4段107號12樓
                            </p>

                        </div>

                    </div>

                </div>

            </div>
        </AppLayout>
    );
};

export default PageTermsAndConditions07;
